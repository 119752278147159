import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditProjectReportDto, BaseForm } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditProjectDataService } from '../../services/data/audit-project-data.service';
import { AuditProjectReportDataService } from '../../services/data/audit-project-report-data.service';

@Component({
  selector: 'app-audit-project-report-item-form',
  templateUrl: './audit-project-report-item-form.component.html',
  styleUrls: ['./audit-project-report-item-form.component.scss'],
})
export class AuditProjectReportItemFormComponent extends BaseForm<AuditProjectReportDto> implements OnInit {
  contextVariables: any[] = [];
  searchUrl;
  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditProjectReportDataService,
    private projectRequestService: AuditProjectDataService
  ) {
    super(viewModeService, 'AUDIT_PROJECT_REPORT');
    this.searchUrl = projectRequestService.myProjectManagerSearchUrl;
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      auditProject: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
